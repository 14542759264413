// Colors
@import 'colors';

/*********************
****** Keyframes
*********************/

@keyframes spawn {
    0% {}
    75% {
        opacity: 0;
    }
    100% {
        transform: translateX(10vw);
        opacity: 0;
    }
}


/*********************
****** General
*********************/

// Add padding for sections
@media screen and (min-width:576px) {
    #start,
    #service,
    #contact,
    #special {
        padding-top: 4rem;
    }
    footer {
        margin-top: 3rem;
    }
}

#service,
#special {
    padding-top: 4rem;
} // Add transition for changing elements
div,
article,
section {
    transition: .4s transform, .4s margin, .4s width, .4s max-width;
} // Deault UL style
ul {
    padding: 0;
    li {
        list-style: none;
    }
}


/*********************
****** Header
*********************/

#mainNav {
    z-index: 100;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
} // Display #quickInfo above navigation
@media (min-width: 992px) {
    #mainHeader {
        display: flex;
        flex-direction: column-reverse;
    }
}

#quickInfo {
    div:last-of-type {
        margin: 0 !important;
        >a {
            height: 2rem;
            width: 2rem;
            background: white url('../imgs/menu.svg') center no-repeat;
            border-bottom-left-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }
    }
    img {
        display: inline-block;
        position: relative;
        top: -.4em;
        margin-right: 1em;
        margin-top: .5em;
        height: 2em;
        width: 2em;
    }
} // Change apperiance on large version
@media screen and (min-width: 992px) {
    #quickInfo {
        div {
            padding: 0;
        }
        h1,
        p {
            display: none;
        }
        ul {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin: 0;
            font-size: .8em;
        }
        img {
            position: initial;
            margin-top: 0;
        }
    }
}


/*********************
****** Heros
*********************/

.hero {
    height: 80vh;
    background: darken($blue, 30) no-repeat center fixed;
    background-size: cover;
    margin-top: -2rem;
    overflow: hidden;
    padding: 1rem 0;
    >div {
        animation: 3s spawn reverse;
        animation-timing-function: ease-in;
    }
    h2>span {
        font-size: .75em;
    }
}

@media screen and (max-width:444px) {
    .hero {
        height: 100vh;
    }
}

@media screen and (max-width:767px) and (orientation: landscape) {
    .hero {
        height: 100vh;
    }
}

#start {
    background-image: url('../imgs/mustang.jpeg') ;
}

@media screen and (min-width:768px) {
    #start {
        background-image: url('../imgs/mustangXL.jpeg');
    }
}

#hService {
    background-image: url('../imgs/shift.jpg') ;
}

@media screen and (min-width:768px) {
    #hService {
        background-image: url('../imgs/shiftXL.jpg');
    }
}

#hContact {
    background-image: url('../imgs/city.jpg') ;
}

@media screen and (min-width:768px) {
    #hContact {
        background-image: url('../imgs/cityXL.jpg');
    }
}

#hFAQ {
    background-image: url('../imgs/body.jpg') ;
}

@media screen and (min-width:768px) {
    #hFAQ {
        background-image: url('../imgs/bodyXL.jpg');
    }
}

#hAbout {
    background-image: url('../imgs/lamp.jpg') ;
}

@media screen and (min-width:768px) {
    #hAbout {
        background-image: url('../imgs/lampXL.jpg');
    }
}

#hCareer {
    background-image: url('../imgs/person.jpg') ;
}

@media screen and (min-width:768px) {
    #hCareer {
        background-image: url('../imgs/personXL.jpg');
    }
}


/*********************
****** Overview
*********************/

#overview {
    background-color: white;
    article {
        padding: 1rem;
    }
    img {
        position: relative;
        top: -2px;
    }
}

@media screen and (min-width:768px) {
    #overview {
        transform: translateY(-5%);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }
}

@media screen and (max-height:950px) {
    #overview {
        transform: translateY(0);
    }
}

@media screen and (min-width:992px) and (min-height:848px) {
    #overview {
        transform: translateY(-5%);
    }
}

@media screen and (min-width:768px) {
    #hCareer {
        background-image: url('../imgs/personXL.jpg');
    }
}

/*********************
****** contentBox/search
*********************/
#contentBox {
    padding: 2rem;
    background-color: white;
    transform: translateY(-4rem);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    transition: .4s box-shadow;
    border-radius: .25rem;
}

.search > ul > li:not( .is-active ) > div
{
    display: none;
}

.search {
    padding: 0;
    .alphabet {
        font-size: 1.5em;
        font-weight: bold;
        border-bottom: none;
    }
}

.search > ul > li {
    border-bottom: 1px solid #ced4da;
    padding: 1rem 0;
    &:last-of-type{
        border-bottom: none;
    }
}

.search div.form-group {
    margin: 1rem 0;
}

.highlight {
    background-color: #ffc107;
}

.is-hidden {
    display: none;
}

.is-visible {
    display: block;
}


/*********************
****** Service
*********************/

#service {
    #car {
        position: relative;
    }
    #con {
        width: 40%;
        max-width: 187px;
        position: absolute;
        bottom: 1rem;
        right: 0;
    }
}

@media screen and (min-width: 768px) {
    #service {
        #car {
            display: flex;
            flex-direction: column;
        }
        #con {
            width: 40%;
            max-width: 187px;
        }
        #carOptions {
            ul {
                z-index: 2;
                height: 100%;
                width: 100%;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 0;
                li {
                    cursor: default;
                    width: 2rem;
                    height: 2rem;
                    background: #007bff;
                    border: 4px solid white;
                    border-radius: 50%;
                    &:hover span {
                        display: inline;
                    }
                    span {
                        display: none;
                        background: white;
                        font-size: 1.25em;
                        border-radius: .25rem;
                        padding: .5rem;
                    }
                }
                li:nth-child(1) {
                    position: absolute;
                    top: 73%;
                    left: 50%;
                }
                li:nth-child(2) {
                    position: absolute;
                    top: 14%;
                    left: 43%;
                }
                li:nth-child(3) {
                    position: absolute;
                    top: 35%;
                    left: 30%;
                }
                li:nth-child(4) {
                    position: absolute;
                    top: 40%;
                    left: 12%;
                }
            }
        }
        .btn {
            margin-top: 2rem;
        }
    }
}


/*********************
****** Special
*********************/

#special {
    article {
        display: flex;
        justify-content: center;
        position: relative;
        overflow: hidden;
        &:hover {
            img.img-fluid {
                transform: scale(1.1);
            }
            .overlay {
                background: rgba(0, 0, 0, 0.9);
            }
        }
        img.img-fluid {
            position: absolute;
            z-index: -2;
            min-height: 100%;
            height: auto;
            width: 100%;
            transition: .4s transform ease-in-out;
        }
        .overlay {
            background: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            transition: .4s background ease-in-out;
        }
        h3 {
            padding-top: 1rem;
        }
        p {
            padding-bottom: 1rem;
        }
    }
}

#fb {
    color: #212529;
    background-color: darken(white, 25);
    padding-bottom: 2rem;
    img {
        height: 5rem;
        float: left;
        padding-top: 1.4rem;
        padding-right: .5rem;
    }
}

@media screen and (min-width: 1200px) {
    #special {
        article {
            &:nth-child(1) {
                border-top-left-radius: .25rem;
                border-bottom-left-radius: .25rem;
            }
            &:last-of-type {
                border-top-right-radius: .25rem;
                border-bottom-right-radius: .25rem;
            }
        }
    }
}


/*********************
****** Contact
*********************/

@media screen and (max-width: 1199px) {
    #contact {
        iframe {
            padding: 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    #contact {
        iframe {
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
            padding-left: 0;
        }
        div.bg-warning {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }
    }
}


/*********************
****** Footer
*********************/

footer {
    background-color: #212529;
    img {
        height: 1.5rem;
        padding-right: .5rem;
        float: left;
    }
    ul ul li {
        padding-left: 1rem;
    }
}